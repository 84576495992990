import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FlowbiteService, PlatformIdService } from '@ckmk/angular';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit, OnChanges {
  public constructor(
    private readonly _platformIdService: PlatformIdService,
    private readonly _flowbiteService: FlowbiteService,
  ) {}

  public ngOnInit() {
    this._flowbiteService.load((flowbite) => {
      flowbite.initFlowbite();

      console.log('Flowbite loaded', flowbite);
    });
  }

  public ngOnChanges(changes: SimpleChanges) {}

  protected isAdminRoute(): boolean {
    return (
      this._platformIdService.isBrowser() &&
      document.location.pathname.startsWith('/admin-')
    );
  }
}
