import { Route } from '@angular/router';
import { EventsComponent } from '../../../../modules/front/modules/agenda/views/events/events.component';
import { EventShowComponent } from '../../../../modules/front/modules/agenda/views/event-show/event-show.component';

export default {
  path: 'agenda',
  children: [
    {
      path: '',
      component: EventsComponent,
    },
    {
      path: ':event',
      component: EventShowComponent,
    },
  ],
} as Route;
