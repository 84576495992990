import { Route } from '@angular/router';
import { GalleryComponent } from '../../../../modules/front/modules/gallery/views/gallery/gallery.component';

export default {
  path: 'gallery',
  children: [
    {
      path: '',
      component: GalleryComponent,
    },
  ],
} as Route;
