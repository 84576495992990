import { Route } from '@angular/router';
import { ProgramsComponent } from '../../../../modules/front/modules/programs/views/programs/programs.component';
import { ProgramShowComponent } from '../../../../modules/front/modules/programs/views/program-show/program-show.component';

export default {
  path: 'programs',
  children: [
    {
      path: '',
      component: ProgramsComponent,
    },
    {
      path: ':program',
      component: ProgramShowComponent,
    },
  ],
} as Route;
