import {
  Component,
  ElementRef,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { FrontContentComponent } from '../../../../components/front-content/front-content.component';
import { HomeAboutComponent } from '../../../../components/home-about/home-about.component';
import { HomeNewsComponent } from '../../../../components/home-news/home-news.component';
import { HomeProgramsComponent } from '../../../../components/home-programs/home-programs.component';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    FrontContentComponent,
    HomeAboutComponent,
    HomeNewsComponent,
    HomeProgramsComponent,
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
})
export class HomeComponent implements OnInit, OnChanges {
  public constructor(private readonly _self: ElementRef) {}

  public ngOnInit() {}

  public ngOnChanges(changes: SimpleChanges) {}
}
